import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from 'src/environments/environment';
import { LaunchDarklyInfo } from './launchdarkly-info';
import { ModalTemplate } from 'src/app/cl-modal/modal-template';
import { ClModalComponent } from 'src/app/cl-modal/cl-modal.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapperService } from 'src/app/services/bootstrapper.service';
@Injectable(
  { providedIn: 'root' }
)
export class LaunchDarklyService {

  private connectionFailed = false;
  private ldClient: LDClient;
  allFeatureFlags: LDFlagSet = {
    flags_by_environment: {},
    flags_by_country: {}
  };
  onChangeFeatureFlag = new Subject();
  countryCode = '';

  onReadyLD = new BehaviorSubject<boolean>(false);
  modalRef: NgbModalRef;

  constructor(
    private ngbModal: NgbModal,
    private bootstrapperService: BootstrapperService) { }

  async initializeLD() {
    return new Promise((resolve, reject) => {

      this.connectionFailed = false;
      this.ldClient = initialize(environment.featureFlagClientId, { anonymous: true });

      this.ldClient.on('ready', () => {
        if (!this.connectionFailed) {
          // once LD establishes communication then start accessing feature flags
          this.setFeatureFlags(this.ldClient.allFlags());
          this.onReadyLD.next(true);
          resolve('done');
          console.log('LaunchDarkly Success: Connection established.');
          console.log('Flags updated.');
        } else {
          this.setDefaultFeatureFlagValues();
          this.onReadyLD.next(true);
          reject('error');
        }
      });

      this.ldClient.on('failed', (error) => {
        this.connectionFailed = true;
        console.error('LaunchDarkly Error: Failed to establish connection. ', error);
      });

      this.ldClient.on('error', (error) => {
        this.connectionFailed = true;
        console.error('LaunchDarkly Error: Has encountered an error. ', error);
      });

      // subscribe to all FF change notification
      this.ldClient.on('change', (modifiedFlag) => {
        this.setFeatureFlags(this.ldClient.allFlags());
        this.onChangeFeatureFlag.next(modifiedFlag);
        const message = { headline: 'feature_flag.change_title', body: 'feature_flag.change_content' };
        this._informChanges(message);
        console.log('Flags modified.');
      });

      // subscribe to a specific FF change notification
      // this.ldClient.on(`change:${flag_key}`, (featureFlag) => {
      // });
    })
      .catch((error) => {
        console.error('LaunchDarkly error: ', error);
      });
  }

  private setDefaultFeatureFlagValues() {
    console.log('Setting default values for the feature flags');
    this.allFeatureFlags.flags_by_environment = this._computeFlagsForEnvioremnt(LaunchDarklyInfo.featureFlagsByEnvironmentDefault);
    this.allFeatureFlags.flags_by_country = LaunchDarklyInfo.featureFlagsBycountryDefault;
    this.allFeatureFlags.default = true;
  }

  private setFeatureFlags(allFlags: any) {
    this.allFeatureFlags = allFlags;
    this.allFeatureFlags.flags_by_environment = this._computeFlagsForEnvioremnt(this.allFeatureFlags.flags_by_environment);
    this.allFeatureFlags.default = false;
  }

  private _computeFlagsForEnvioremnt(flags_by_environment) {
    const partnerId = this.bootstrapperService.getPartnerId();
    return flags_by_environment[partnerId];
  }

  setCountryCode(countryCode) {
    this.countryCode = countryCode;
  }

  // check if the provided version is smaller or equal to the console-version in LD
  isFeatureEnabledByVersion(version: string) {
    return parseFloat(this.allFeatureFlags.flags_by_environment['console-version']) >= parseFloat(version);
  }

  // check if the provided flag_name is exist for the current country in LD
  isFeatureEnabledByCountry(flag_name: string) {
    if (this.allFeatureFlags.flags_by_country[this.countryCode].hasOwnProperty(flag_name)) {
      return this.allFeatureFlags.flags_by_country[this.countryCode][flag_name];
    } else {
      return !!this.allFeatureFlags.flags_by_country['all'][flag_name];
    }
  }

  // used for checking the flag based on the provided method and their values
  isEnabled(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!this[key](data[key])) {
          return false;
        }
      }
    }
    return true;
  }

  private _informChanges(message) {
    /* istanbul ignore else */
    if (!this.modalRef) {
      const modTemp: ModalTemplate = {
        title: message.headline,
        content: message.body,
        isContentHTML: false,
        primaryBtn: {
          value: 'ok',
          label: 'shared_ok',
          width: 137
        },
        defaultBtn: {
          value: 'cancel',
          label: 'shared_cancel',
          width: 137
        }
      };

      const options: NgbModalOptions = {
        windowClass: 'sm-modal',
        backdrop: 'static'
      };

      this.modalRef = this.ngbModal.open(ClModalComponent, options);
      this.modalRef.componentInstance.modalTemp = modTemp;

      this.modalRef.result.then((result) => {
        this.modalRef = null;
        if (result === 'ok') {
          window.location.reload();
        }
      }, (reason) => {
        // canceled via Esc key
        this.modalRef = null;
      });
    }
  }
}

