import { PartnerInfo } from 'src/app/services/app-info';

/* Default values for launchdarkly feature flags */
export const LaunchDarklyInfo = {

  // add here the default values if there are any new feature flags
  featureFlagsByEnvironmentDefault: {
    [PartnerInfo.productionUS.partnerId]: {
      'commonName': PartnerInfo.productionUS.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.alpha.partnerId]: {
      'commonName': PartnerInfo.alpha.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.can01.partnerId]: {
      'commonName': PartnerInfo.can01.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.3'
    },
    [PartnerInfo.mift.partnerId]: {
      'commonName': PartnerInfo.mift.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.sit.partnerId]: {
      'commonName': PartnerInfo.sit.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.utit.partnerId]: {
      'commonName': PartnerInfo.utit.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.performance.partnerId]: {
      'commonName': PartnerInfo.performance.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.bossUSQA.partnerId]: {
      'commonName': PartnerInfo.bossUSQA.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false
    },
    [PartnerInfo.bossSCOQA.partnerId]: {
      'commonName': PartnerInfo.bossSCOQA.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.ukdMAC.partnerId]: {
      'commonName': PartnerInfo.ukdMAC.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.ukqWIN.partnerId]: {
      'commonName': PartnerInfo.ukqWIN.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.3'
    },
    [PartnerInfo.productionUK.partnerId]: {
      'commonName': PartnerInfo.productionUK.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.euq.partnerId]: {
      'commonName': PartnerInfo.euq.commonName,
      'phoneapi-latest': true,
      'sdapi-latest': true,
      'console-version': '3.4'
    },
    'default': true
  },
  featureFlagsBycountryDefault: {
    '124': {
      'commonName': 'CANADA',
      'emergency_console': false
    },
    '276': {
      'commonName': 'GERMANY',
      'emergency_console': true
    },
    '826': {
      'commonName': 'UK',
      'emergency_console': false
    },
    '840': {
      'commonName': 'US',
      'emergency_console': false
    },
    'all': {
      'commonName': 'ALL',
      'hardware_console': true,
      'inventory_console': true,
      'number_purchase': true,
      'transfer_request': true
    },
    'default': false
  }
};

