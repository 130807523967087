

import { HierarchyDatum, FlowComponentCRUDActionModel, CallFlowComponentType } from '../models/flow-model';

export const rightPanelCloseReason = {
    STAY: 'stay',
    LEAVE: 'leave',
    COLLAPSE: 'collapse',
    NAVIGATEAWAY: 'navigateaway',
    BACKDROP: 'backdrop',
    ESC: 'esc'
};

// Call flow visualization constants
export const nodeColor = '#7E8892';
export const edgeColor = '#7E8892';
export const linkColor = '#0073D0';
export const duration = 400;
export const newComponentId = '999999999999999999';

export const startingNode: HierarchyDatum = {
    'name': 'Start',
    'subText': '',
    'id': 0,
    'componentType': 'start',
    'connection_text': null,
    children: []
};

export const flowComponentCRUDActionValues: {
    read: FlowComponentCRUDActionModel,
    create: FlowComponentCRUDActionModel,
    update: FlowComponentCRUDActionModel,
    delete: FlowComponentCRUDActionModel,
} = {
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete'
};

export const callFlowComponentTypeValues: {
    aa: CallFlowComponentType,
    hg: CallFlowComponentType,
    start: CallFlowComponentType,
    empty: CallFlowComponentType
} = {
    aa: 'Auto-Attendant',
    hg: 'Hunt Group',
    start: 'start',
    empty: ''
};

export const PartnersType = {
    'support': 1,
    'var': 2
};

export const LOCATION_QUERY_FIELD = 'locationUuid';

export enum TableSortingOrder {
    asc = 1,
    desc = -1
}

export enum Days {
    Sunday = 0,
    Monday = 1
}

export enum DateFormats {
    DDMMYY = 'dd/mm/yy',
    MMDDYY = 'mm/dd/yy',
    DDMMYYYY = 'dd/MM/yyyy',
    MMDDYYYY = 'MM/dd/yyyy'
}

export enum HoursFormats {
    Hour12 = '12',
    Hour24 = '24'
}

// Country.Values mapping from M5.Entities.Base.Country
export enum Countries {
    Unknown = 0,
    Afghanistan = 4,
    Albania = 8,
    Antarctica = 10,
    Algeria = 12,
    AmericanSamoa = 16,
    Andorra = 20,
    Angola = 24,
    AntiguaAndBarbuda = 28,
    Azerbaijan = 31,
    Argentina = 32,
    Australia = 36,
    Austria = 40,
    Bahamas = 44,
    Bahrain = 48,
    Bangladesh = 50,
    Armenia = 51,
    Barbados = 52,
    Belgium = 56,
    Bermuda = 60,
    Bhutan = 64,
    Bolivia = 68,
    BosniaAndHerzegowina = 70,
    Botswana = 72,
    BouvetIsland = 74,
    Brazil = 76,
    Belize = 84,
    BritishIndianOceanTerritory = 86,
    SolomonIslands = 90,
    VirginIslandsBritish = 92,
    BruneiDarussalam = 96,
    Bulgaria = 100,
    MyanmarBurma = 104,
    Burundi = 108,
    Belarus = 112,
    Cambodia = 116,
    Cameroon = 120,
    Canada = 124,
    CapeVerde = 132,
    CaymanIslands = 136,
    CentralAfricanRepublic = 140,
    SriLanka = 144,
    Chad = 148,
    Chile = 152,
    China = 156,
    TaiwanProvinceOfChina = 158,
    ChristmasIsland = 162,
    CocosKeelingIslands = 166,
    Colombia = 170,
    Comoros = 174,
    Mayotte = 175,
    Congo = 178,
    CongoTheDrc = 180,
    CookIslands = 184,
    CostaRica = 188,
    Croatia = 191,
    Cuba = 192,
    Cyprus = 196,
    CzechRepublic = 203,
    Benin = 204,
    Denmark = 208,
    Dominica = 212,
    DominicanRepublic = 214,
    Ecuador = 218,
    ElSalvador = 222,
    EquatorialGuinea = 226,
    Ethiopia = 231,
    Eritrea = 232,
    Estonia = 233,
    FaroeIslands = 234,
    FalklandIslandsMalvinas = 238,
    SouthGeorgiaAndSouthSS = 239,
    Fiji = 242,
    Finland = 246,
    FranceMetropolitan = 249,
    France = 250,
    FrenchGuiana = 254,
    FrenchPolynesia = 258,
    FrenchSouthernTerritories = 260,
    Djibouti = 262,
    Gabon = 266,
    Georgia = 268,
    Gambia = 270,
    Germany = 276,
    Ghana = 288,
    Gibraltar = 292,
    Kiribati = 296,
    Greece = 300,
    Greenland = 304,
    Grenada = 308,
    Guadeloupe = 312,
    Guam = 316,
    Guatemala = 320,
    Guinea = 324,
    Guyana = 328,
    Haiti = 332,
    HeardAndMcDonaldIslands = 334,
    HolySeeVaticanCityState = 336,
    Honduras = 340,
    HongKong = 344,
    Hungary = 348,
    Iceland = 352,
    India = 356,
    Indonesia = 360,
    IranIslamicRepublicOf = 364,
    Iraq = 368,
    Ireland = 372,
    Israel = 376,
    Italy = 380,
    CoteDIvoire = 384,
    Jamaica = 388,
    Japan = 392,
    Kazakhstan = 398,
    Jordan = 400,
    Kenya = 404,
    KoreaDPRO = 408,
    KoreaRepublicOf = 410,
    Kuwait = 414,
    Kyrgyzstan = 417,
    Laos = 418,
    Lebanon = 422,
    Lesotho = 426,
    Latvia = 428,
    Liberia = 430,
    LibyanArabJamahiriya = 434,
    Liechtenstein = 438,
    Lithuania = 440,
    Luxembourg = 442,
    Macau = 446,
    Madagascar = 450,
    Malawi = 454,
    Malaysia = 458,
    Maldives = 462,
    Mali = 466,
    Malta = 470,
    Martinique = 474,
    Mauritania = 478,
    Mauritius = 480,
    Mexico = 484,
    Monaco = 492,
    Mongolia = 496,
    MoldovaRepublicOf = 498,
    Montserrat = 500,
    Morocco = 504,
    Mozambique = 508,
    Oman = 512,
    Namibia = 516,
    Nauru = 520,
    Nepal = 524,
    Netherlands = 528,
    NetherlandsAntilles = 530,
    Aruba = 533,
    NewCaledonia = 540,
    Vanuatu = 548,
    NewZealand = 554,
    Nicaragua = 558,
    Niger = 562,
    Nigeria = 566,
    Niue = 570,
    NorfolkIsland = 574,
    Norway = 578,
    NorthernMarianaIslands = 580,
    USMinorIslands = 581,
    MicronesiaFederatedStatesOf = 583,
    MarshallIslands = 584,
    Palau = 585,
    Pakistan = 586,
    Panama = 591,
    PapuaNewGuinea = 598,
    Paraguay = 600,
    Peru = 604,
    Philippines = 608,
    Pitcairn = 612,
    Poland = 616,
    Portugal = 620,
    GuineaBissau = 624,
    EastTimor = 626,
    PuertoRico = 630,
    Qatar = 634,
    Reunion = 638,
    Romania = 642,
    RussianFederation = 643,
    Rwanda = 646,
    StHelena = 654,
    SaintKittsAndNevis = 659,
    Anguilla = 660,
    SaintLucia = 662,
    StPierreAndMiquelon = 666,
    SaintVincentAndTheGrenadines = 670,
    SanMarino = 674,
    SaoTomeAndPrincipe = 678,
    SaudiArabia = 682,
    Senegal = 686,
    Seychelles = 690,
    SierraLeone = 694,
    Singapore = 702,
    SlovakiaSlovakRepublic = 703,
    VietNam = 704,
    Slovenia = 705,
    Somalia = 706,
    SouthAfrica = 710,
    Zimbabwe = 716,
    Spain = 724,
    WesternSahara = 732,
    Sudan = 736,
    Suriname = 740,
    SvalbardAndJanMayenIslands = 744,
    Swaziland = 748,
    Sweden = 752,
    Switzerland = 756,
    SyrianArabRepublic = 760,
    Tajikistan = 762,
    Thailand = 764,
    Togo = 768,
    Tokelau = 772,
    Tonga = 776,
    TrinidadAndTobago = 780,
    UnitedArabEmirates = 784,
    Tunisia = 788,
    Turkey = 792,
    Turkmenistan = 795,
    TurksAndCaicosIslands = 796,
    Tuvalu = 798,
    Uganda = 800,
    Ukraine = 804,
    Macedonia = 807,
    Egypt = 818,
    UnitedKingdom = 826,
    TanzaniaUnitedRepublicOf = 834,
    UnitedStates = 840,
    VirginIslandsUS = 850,
    BurkinaFaso = 854,
    Uruguay = 858,
    Uzbekistan = 860,
    Venezuela = 862,
    WallisAndFutunaIslands = 876,
    Samoa = 882,
    Yemen = 887,
    YugoslaviaSerbiaAndMontenegro = 891,
    Zambia = 894
}

export const CountryLanguageMapping = [
    { countryCode: Countries.UnitedStates, id: "1", language: "languages.english_us" },
    { countryCode: Countries.Spain, id: "2", language: "languages.spanish_spain" },
    { countryCode: Countries.Germany, id: "3", language: "languages.german" },
    { countryCode: Countries.UnitedKingdom, id: "4", language: "languages.english_uk" },
    { countryCode: Countries.France, id: "5", language: "languages.french" },
    { countryCode: Countries.Italy, id: "9", language: "languages.italian" },
    { countryCode: Countries.Australia, id: "23", language: "languages.english_au" },
    { countryCode: Countries.Canada, id: "1", language: "languages.english_us" },
];

export const ScheduleMapping = [
    { id: "1", displayName: "aa.schedules.on_hours" },
    { id: "181", displayName: "aa.schedules.new_holiday_schedule" },
    { id: "185", displayName: "aa.schedules.new_on_hours_schedule" },
    { id: "204", displayName: "aa.schedules.custom_schedule" },
    { id: "205", displayName: "aa.schedules.test_on_hours" },
    { id: "206", displayName: "aa.schedules.test_holiday" },
    { id: "207", displayName: "aa.schedules.test_custom" },
];

export const LanguagesMapping = [
    { value: "1", displayLabel: "languages.english_us" },
    { value: "2", displayLabel: "languages.spanish_spain" },
    { value: "3", displayLabel: "languages.german" },
    { value: "4", displayLabel: "languages.english_uk" },
    { value: "5", displayLabel: "languages.french" },
    { value: "6", displayLabel: "languages.dutch" },
    { value: "7", displayLabel: "languages.spanish_cala" },
    { value: "8", displayLabel: "languages.danish" },
    { value: "9", displayLabel: "languages.italian" },
    { value: "10", displayLabel: "languages.swedish" },
    { value: "11", displayLabel: "languages.chinese_simplified" },
    { value: "12", displayLabel: "languages.norwegian" },
    { value: "13", displayLabel: "languages.portuguese_brazil" },
    { value: "14", displayLabel: "languages.japanese" },
    { value: "15", displayLabel: "languages.korean" },
    { value: "17", displayLabel: "languages.chinese_traditional" },
    { value: "18", displayLabel: "languages.portuguese_portugal" },
    { value: "23", displayLabel: "languages.english_au" },
    { value: "26", displayLabel: "languages.hebrew" },
    { value: "27", displayLabel: "languages.franch_ca" }

];
